<script>
import GlobalVue from '@helper/Global.vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';


export default {
    extends: GlobalVue,
    data() {
        return {
          data:{},
          cat:{},
          related:{},
        }
    },
    watch: {
        $route() {
           this.getProduct();
           this.refreshMeta();
           $("body").scrollTop(0);
        }
    },
    async mounted() {
        this.$set(this.$root, 'page', this)
        this.getProduct()
        this.refreshMeta()
    },
    methods: {
      getProduct() {
        Gen.apirest("/product-detail/"+this.$route.params.cat+'/'+this.$route.params.slug,{}, (err, resp) => {
          if (err) console.log(err)
          if (resp.code == 404) return this.$router.push({name:'404'})
          this.data = resp.data
          this.cat = resp.cat
          this.related = resp.related
          setTimeout(()=>{            
            SEMICOLON.widget.carousel();
            AOS.init({ once: true });
          }, 100)
        })
      },
      refreshMeta(){
        console.log('meta')
        Gen.rest(this.$route.path, {metainfo:true},(err,resp)=>{
          if(err) return console.error(err)
          $("title").text(resp.meta_title)
          $("meta[property='og:image']").attr('content', resp.ss_img)
          $("meta[property='og:title']").attr('content', resp.meta_title)
          $("meta[name='description']").attr('content', resp.ss_meta_desc)
          $("meta[property='og:description']").attr('content', resp.ss_meta_desc)
          $("meta[name='keywords']").attr('content', resp.ss_meta_keyword)
          $("meta[name='author']").attr('content', resp.ss_author)
          $("meta[property='og:site_name']").attr('content', resp.ss_sitename)
          $("meta[property='url']").attr('content', resp.ss_url)
          $("link[rel='canonical']").attr('content', resp.ss_url)
          this.$root.meta = resp
        })
      },
    }
};
</script>

<template>
     <section id="content">
      <div class="content-wrap py-0">
        <section id="page-title" class="page-title-mini">
          <div class="container clearfix">
            <h1>{{data.ap_name}}</h1>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{name:'Index'}">{{web.menu_home}}</router-link></li>
              <li class="breadcrumb-item"><router-link :to="{name:'Product'}">{{web.menu_product}}</router-link></li>
              <li class="breadcrumb-item"><router-link :to="{name:'ProductCat',params:{cat:cat.apc_slug}}">{{cat.apc_name}}</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">{{data.ap_name}}</li>
            </ol>
          </div>
        </section>
        <section class="section bg_blue_general nomargin">
          <div class="container">
            <div class="blog-post">
              <div class="row">
                <div class="col-lg-4 col-md-5" data-aos="fade-up">
                  <div class="wrap_product_thumb">
                    <a href="#"><img :src="uploader(data.ap_img)" :alt="data.ap_name"></a>
                  </div>

                  <div class="claim_wrapper mt-3" v-if="data.claim.length">
                    <ul>
                      <li class="mr-2" data-aos="zoom-out" v-for="(c,ck) in data.claim">
                        <router-link :to="{name:'HealthInfo',query:{tag:c.title}}" :class="c.class"><p>{{c.title}}</p></router-link>
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="col-md-7 col-lg-5" data-aos="fade-up" data-aos-delay="150">
                  <div class="product_detail_wrap mb-3">
                    <h1>{{data.ap_name}}</h1>
                    <div v-html="data.ap_desc"></div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div v-if="data.ap_link != null" class="col-md-5 col-lg-5" data-aos="fade-up" data-aos-delay="150">
                      <a :href="data.ap_link" target="_blank" class="cta cta-primary cta--reveal mw-auto d-block"><span>Buy Now</span><i class="icon-line-arrow-right"></i></a>
                    </div>
                    <div class="col-md-7" data-aos="fade-up" data-aos-delay="300">
                      <a :href="uploader(data.ap_img_gizi)" data-lightbox="image" class="cta mw-auto cta-border-primary d-block d-lg-none mt-2 mt-md-0">Nutrition Facts</a>
                    </div>
                  </div>
                </div>
                <div class="col-md-3" data-aos="fade-up" data-aos-delay="450">
                  <div class="nutrition_fact_wrap d-none d-lg-block mt-0">
                    <img :src="uploader(data.ap_img_gizi,'360')" :alt="data.ap_name" />
                    <div class="overlay_nutrition">
                      <a href="#" data-toggle="modal" data-target="#exampleModal"><i class="icon-search"></i></a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section bg-blue bg-fixer" v-if="related.length">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading-block mb-0 center">
                  <h2>{{web.lbl_related_product}}</h2>
                </div>
                <div id="oc-clients" class="owl-carousel image-carousel carousel-widget" data-margin="60"
                  data-loop="true" data-nav="true" data-autoplay="5000" data-pagi="false" data-items-xs="2"
                  data-items-sm="3" data-items-md="4" data-items-lg="5" data-items-xl="5">
                  <div class="oc-item" data-aos="fade-up" v-for="(r,rk) in related" :key="rk">
                    <div class="product_wrapper">
                      <router-link :to="{name:'ProductDetail',params:{cat:r.apc_slug,slug:r.ap_slug}}"><img :src="uploader(r.ap_img)" :alt="r.ap_name"></router-link>
                      <div class="product_desc">
                        <h3>{{r.ap_name}}</h3>
                        <p>{{r.ap_netto}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-bottom"></div>
        </section>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModal" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{data.ap_name}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="wrap_nutrition_fact_detail">
                  <img :src="uploader(data.ap_img_gizi)" :alt="data.ap_name" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{web.lbl_btn_close}}</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>